import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { parseISO, format } from 'date-fns';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Wrap from '../components/Wrap';
import Paragraph from '../components/Paragraph';
import CTAButton from '../components/CTAButton';
import FormCTA from '../components/IndexPage/FormCTA';
import Process from '../components/IndexPage/Process';
import WhatWeOffer from '../components/IndexPage/WhatWeOffer';
import Experts from '../components/IndexPage/Experts';
import SlackButton from '../components/SlackButton';
import XArea from '../components/XArea';
import H1 from '../components/H1';
import Markdown from '../components/Markdown';

const BackgroundTop = styled.div`
  background: #eff5f7;
  padding-top: 50px;
  overflow: hidden;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  ${p => p.theme.l} {
    margin: 0 60px;
  }
`;

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;

const Column = styled.div`
  flex: 1;
  padding: 0 15px 50px 15px;
  display: flex;
  flex-direction: column;
`;

const ContactButton = styled.a`
  position: relative;
  display: inline-block;
  background: white;
  box-shadow: 0px 2px 3px rgba(0, 43, 92, 0.1);
  border: 1px solid #d9ebf3;
  background-clip: padding-box;
  border-radius: 28px;
  font-family: Futura;
  font-weight: 500;
  color: inherit;
  text-decoration: none;
  padding: 0 24px;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 80px 0;
  @media (max-width: 374px) {
    padding: 0 15px;
    font-size: 14px;
  }
  p {
    margin: 1rem !important;
  }
  ${p => p.theme.ml} {
    padding: 0 32px;
    font-size: 20px;
    border-radius: 35px;
  }
  :hover,
  :focus {
    opacity: 0.9;
  }
  :active {
    opacity: 0.85;
  }
`;

const Page = () => {
  const data = useStaticQuery(graphql`
    query {
      doc: contentfulPage(id: { eq: "6578f9fe-a5d6-50ef-9a86-1c0aa6ae4688" }) {
        heroTitle1
        heroTitle2
        heroText {
          heroText
        }
        heroImage {
          fluid(maxWidth: 768, maxHeight: 512, cropFocus: CENTER, quality: 90) {
            ...GatsbyContentfulFluid
          }
        }
        contactHeader
        contactDesc {
          childMarkdownRemark {
            html
          }
        }
        columns {
          logo {
            file {
              url
            }
          }
          description {
            description
          }
          ctaTitle
          ctaLink
        }
      }
    }
  `);
  return (
    <Layout hasXMenu={true}>
      <SEO title="Home" />
      <Hero
        image={data.doc.heroImage}
        title={[data.doc.heroTitle1, data.doc.heroTitle2]}
        titleIndent="second"
      />
      <Wrap>
        <Paragraph css={{ marginTop: 0, whiteSpace: 'pre-line' }}>
          {data.doc.heroText.heroText}
        </Paragraph>
      </Wrap>
      <BackgroundTop>
        <Wrap>
          <Columns>
            {data.doc.columns.map((col, i) => (
              <Column>
                {col.logo && (
                  <div>
                    <img src={col.logo.file.url} />
                  </div>
                )}
                <Paragraph>{col.description.description}</Paragraph>
                {col.ctaTitle && (
                  <div style={{ marginTop: 'auto' }}>
                    <CTAButton href={col.ctaLink}>{col.ctaTitle}</CTAButton>
                  </div>
                )}
              </Column>
            ))}
          </Columns>
        </Wrap>
      </BackgroundTop>
      <Wrap>
        <H1 css={{ alignText: 'left' }}>{data.doc.buildingTitle}</H1>
        <Paragraph css={{ whiteSpace: 'pre-line' }}>
          {/*data.doc.buildingText.buildingText*/}
        </Paragraph>
      </Wrap>
      <Wrap>
        <>
          <H1>{data.doc.contactHeader}</H1>
          <ContactButton>
            <Markdown
              style={{ textAlign: 'center' }}
              dangerouslySetInnerHTML={{
                __html: data.doc.contactDesc.childMarkdownRemark.html,
              }}
            />
          </ContactButton>
        </>
      </Wrap>
    </Layout>
  );
};

export default Page;

// const XArea = styled.div`
//   background: white
//     linear-gradient(
//       90deg,
//       rgba(217, 235, 243, 1) 0%,
//       rgba(217, 235, 243, 0.13) 50%
//     );
//   position: relative;

//   padding: 30px 0 30px;
//   ${p => p.theme.m} {
//     padding: 40px 0 40px;
//   }
//   ${p => p.theme.l} {
//     padding: 40px 0 140px;
//     :after {
//       position: absolute;
//       content: '';
//       bottom: 0;
//       height: 80px;
//       width: calc(100% - 120px);
//       max-width: 900px;
//       left: 50%;
//       transform: translateX(-50%);
//       background: white;
//     }
//   }
// `;

const ShiftedH1 = styled(H1)`
  text-align: left;
  ${p => p.theme.l} {
    margin-top: -20px;
    position: relative;
  }
`;

// const XImage = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   background: url('${p => p.image}');
//   background-size: contain;
//   background-position: top left;
//   background-repeat: no-repeat;
//   width: 100%;
//   ~ * {
//     position: relative;
//   }
// `;

// const CoverImage = styled.div`
//   position: relative;
// `;

// const Video = styled.video`
//   width: 100%;
// `;

// const Meta = styled.p`
//   margin: 20px 0 60px;
//   font-weight: bold;
//   span {
//     color: ${p => p.theme.color.accent};
//   }
//   font-size: 14px;
//   ${p => p.theme.l} {
//     font-size: 16px;
//   }
// `;
